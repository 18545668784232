/*
---------------------------------------------------------------------------0000------ ADVERT CARD

------------------------------------------------------------------------------------------------
*/

.advert-wrapper {
    padding-top:56px;
    p {
        font-size: 1em;
        line-height: 1.2;
    }
}

// .summary-card.advert-card {text-align: center; background-color: $primary; color:$white;}

// .summary-card.advert-card .summary-card-content h3 {color:$white;}
// .summary-card.advert-card .summary-card-content p {max-width: 950px; margin-left:auto; margin-right: auto; color:$white;}
// .summary-card.advert-card .summary-card-footer {padding-top:0;}
// .summary-card.advert-card .cta {float:none;}
// .summary-card.advert-card .btn {background-color:$white; color:$primary-dark; border-color:$primary;}

// .summary-card.advert-card .summary-card-img {position: relative; background-color:$bg-swoop;}
// .summary-card.advert-card .summary-card-img:before {display: block; content: ""; width: 100%; padding-top: 55.9375%;} //960 x 537
.summary-card.advert-card .summary-card-img-inner {position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-size:cover; background-position:50% 50%;}
// .summary-card.advert-card .summary-card-img-inner:before {display: block; content: ""; width: 100%; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-color:$bg-swoop; opacity:0.6; z-index:2;}
// .summary-card.advert-card .advert-card-logo {width:40%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index:10;}

// .summary-card.advert-card.advert-card-no-logo .summary-card-img-inner:before {display: none;}

// .summary-card.advert-card .summary-card-img-inner.advert-centered-text {display: flex; justify-content: center; align-items: center;}

// .summary-card.advert-card .summary-card-img-inner.advert-centered-text h2,
// .summary-card.advert-card .summary-card-img-inner.advert-centered-text h3 {
//     padding: 0 20px;
//     margin: 0 0 20px 0;
//     text-align: center;
//     color:$white;
//     z-index: 10;
// }

// .summary-card.advert-card .summary-card-img-inner.advert-centered-text .advert-card-logo {
//     position: relative;
//     top:auto;
//     left:auto;
//     transform: none;
//     display: block;
//     margin: 0 auto 20px auto;
//     width:120px;
// }
// @media screen and (min-width: $break-tablet) {//  720/16

//     .summary-card.advert-card {@include clrfix; text-align:left;}
//     .summary-card.advert-card .summary-card-img {position: absolute; top:0; right:50%; bottom:0; left:0;}
//     .summary-card.advert-card .summary-card-content {max-width: 50%; margin-left: auto;}
//     .summary-card.advert-card .summary-card-content p {font-size:1em;}
//     .summary-card.advert-card .summary-card-img:before {padding-top:0;}
//     .summary-card.advert-card .summary-card-img-inner.advert-centered-text .advert-card-logo {margin-bottom: 50px;}
//     .summary-card.advert-card .summary-card-img-inner.advert-centered-text h3 {font-size: 1.6em;}
// }
