/*
------------------------------------------------------------------------------------------------
  _site-pending-elements.scss - BUTTON ELEMENT BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

// FORM DISABLE

.form-disable {position:relative; @include clrfix;}

.form-disable:before {
  content:''; position:absolute;
  top:0; right:0; bottom:auto; left:0;
  width:100%; height:0;
  background-color:#ffffff; opacity:0; z-index:10;
  transition: opacity 150ms linear;
}

.form-disable.form-disable-dark:before {background-color:#000000;}

.form-disable.disabled:before {bottom:0; height:auto; opacity:0.4;}
.form-disable.disabled.fadeout:before {bottom:0; height:auto; opacity:0;}


// AJAX LOADERS

/*
.ajax-spinner {
  width:19px;
  height:19px;
  background: url('../img/ajax-loader.gif') 0 0 no-repeat;
  display:none;

  @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    background-image: url('../img/retina/ajax-loader.gif');
    background-size: 19px 19px;
  }

}

.ajax-spinner-inline {
  width:19px;
  height:10px;
  background: url('../img/ajax-loader-inline.gif') 0 0 no-repeat;
  display:none;

  @media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    background-image: url('../img/retina/ajax-loader-inline.gif');
    background-size: 16px 4px;
  }

}

.ajax-spinner.ajax-spinner-show {display:block;}
.ajax-spinner-inline.ajax-spinner-show {display:inline-block;}

// CSS ONLY SPINNER

.css-spinner,
.css-spinner:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;

}
.css-spinner {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(50, 50, 50, 0.2);
  border-right: 3px solid rgba(50, 50, 50, 0.2);
  border-bottom: 3px solid rgba(50, 50, 50, 0.2);
  border-left: 3px solid $text-main;
  transform: translateZ(0);
  animation: spinnerAnim 0.5s infinite linear;
  opacity:1;
  transition: opacity 0.2s linear;
}

@keyframes spinnerAnim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-spinner.fade-out-spinner .css-spinner {opacity:0;}
.css-spinner.fade-out {opacity:0;}



// BUTTON SPINNER

.btn-pending {padding-right:42px; position:relative;}
.btn-full.btn-pending {padding-left:42px;} // default padding

.btn-pending:after {
    content:'';
    font-size: 10px;

    display:block;
    width:19px;
    height:19px;

    position:absolute;
    top:8px;
    right:10px;

    border-radius: 50%;

    border-top: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 3px solid $white;

    transform: translateZ(0);
    animation: spinnerAnim 0.5s infinite linear;
    opacity:0;
    transition: opacity 0.2s linear;
}

.btn-pending-show:after {opacity:1;}

.btn-sm.btn-pending:after {top:8px;}

*/
