.sw-bg-navy {
  background-color: $bg-swoop;
}
.sw-orange {
  color: $primary;
}
.sw-orange-bg {
  background-color: $primary;
}
.border-offset-green {
  border-color: $success;
}
.border-sw-navy {
  border-color: $bg-swoop;
}
.border-sw-orange {
  border-color: $primary;
}
.bg-offset-green {
  background-color: $success;
}
.text-green{
  color: $success;
}
.sw-font-sans {
  font-family: "Montserrat", helvetica, sans-serif;
}
.center{
  margin: 0 auto !important;
  left: 0;
  right: 0;
}

.info_icon{
    background-repeat: no-repeat;
    &--sm{
        height: 30px;
        width: 30px;
    }
    &--md{
        height: 60px;
        width: 60px;
    } 
    &--lg{
        height: 100px;
        margin: 14% 0;
        padding: 0 2em;  
    }
} 

.carbon-tag>sub {
  font-size: 0.6em;
}

 .badge {
  display: inline-block;
  font-size: 70%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  position: relative;
  top: -3px;
  left: 5px;
  border-radius: 100%;
  padding: .4rem .6rem;
 }
 .badge-orange {
   background-color: $primary;
   color: $white;
 }
 .badge-navy {
  background-color: $bg-swoop;
  color: #f9f9f9;
}
  .popup {
    opacity: 0;
    visibility: hidden;
    &-container {
      background: #3e506f;
    }
    &-trigger {
      color: #fff;
    }
  }
  .is-visible {
    opacity: 1 !important;
    visibility: visible !important;
  }
  .close:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  .barchart{
    width: 100%;
    fill:#fff;
    animation: riseup 1s 1;
    animation-delay: 1s;
    .bg-gray{
      fill:#a0aec0;
    }
    .active{
      fill:$primary;
    }
  } 

.page-mask {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(4, 30, 66, 0.9);
    z-index: 30;
  }
  
  @keyframes riseup {
    0%  {
      -webkit-clip-path: inset(100% 0 0 0);
      clip-path: inset(100% 0 0 0);
      opacity: 0.3;
    }
    
    100%  {
      -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
        opacity: 1;
    }
  }