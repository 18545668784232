/*flag icons here */
.icon-flag {
  width: 44px;
  height: 44px;
  overflow: hidden;
  word-wrap: normal;
  white-space: nowrap;
  text-indent: 101%;
  text-align: left;
  position: relative;
}
@media (max-width: 834px) {
  .icon-flag {
    width: 40px;
    height: 40px;
  }
}
.flag {
  height: 33px;
  width: 33px;
}

.flag, .icon-flag{
  background-repeat: no-repeat;
  display: inline-block;
}

.flag-au, .icon-aud, .icon-aus {
background-image: url("/assets/front/app/img/svg-icon/flags/au.svg")
}
.flag-be {
background-image: url("/assets/front/app/img/svg-icon/flags/be.svg")
}
.flag-ca, .icon-cad {
background-image: url("/assets/front/app/img/svg-icon/flags/ca.svg")
}
.flag-ch {
background-image: url("/assets/front/app/img/svg-icon/flags/ch.svg")
}
.flag-cz {
background-image: url("/assets/front/app/img/svg-icon/flags/cz.svg")
}
.flag-de {
background-image: url("/assets/front/app/img/svg-icon/flags/de.svg")
}
.flag-dk {
background-image: url("/assets/front/app/img/svg-icon/flags/dk.svg")
}
.flag-es {
background-image: url("/assets/front/app/img/svg-icon/flags/es.svg")
}
.flag-fr {
background-image: url("/assets/front/app/img/svg-icon/flags/fr.svg")
}
.flag-ie {
background-image: url("/assets/front/app/img/svg-icon/flags/ie.svg")
}
.flag-it {
background-image: url("/assets/front/app/img/svg-icon/flags/it.svg")
}
.flag-mx {
background-image: url("/assets/front/app/img/svg-icon/flags/mx.svg")
}
.flag-nl {
background-image: url("/assets/front/app/img/svg-icon/flags/nl.svg")
}
.flag-no {
background-image: url("/assets/front/app/img/svg-icon/flags/no.svg")
}
.flag-nz {
background-image: url("/assets/front/app/img/svg-icon/flags/nz.svg")
}
.flag-pl {
background-image: url("/assets/front/app/img/svg-icon/flags/pl.svg")
}
.flag-pt {
background-image: url("/assets/front/app/img/svg-icon/flags/pt.svg")
}
.flag-sv, .flag-se {
background-image: url("/assets/front/app/img/svg-icon/flags/sv.svg")
}
.flag-us, .icon-usd {
background-image: url("/assets/front/app/img/svg-icon/flags/us.svg")
}
.flag-gb, .icon-gbp {
background-image: url("/assets/front/app/img/svg-icon/flags/gb.svg")
}
.flag-sg {
background-image: url("/assets/front/app/img/svg-icon/flags/sg.svg")
}
.flag-ru {
background-image: url("/assets/front/app/img/svg-icon/flags/ru.svg")
}
.flag-cr {
background-image: url("/assets/front/app/img/svg-icon/flags/cr.svg")
}
.flag-pe {
background-image: url("/assets/front/app/img/svg-icon/flags/pe.svg")
}
.flag-mk {
background-image: url("/assets/front/app/img/svg-icon/flags/mk.svg")
}
.flag-my {
background-image: url("/assets/front/app/img/svg-icon/flags/my.svg")
}
.flag-cn {
background-image: url("/assets/front/app/img/svg-icon/flags/cn.svg")
}
.flag-co {
background-image: url("/assets/front/app/img/svg-icon/flags/co.svg")
}
.flag-lt {
background-image: url("/assets/front/app/img/svg-icon/flags/lt.svg")
}
.flag-ar {
background-image: url("/assets/front/app/img/svg-icon/flags/ar.svg")
}
.flag-kz {
background-image: url("/assets/front/app/img/svg-icon/flags/kz.svg")
}
.flag-gr {
background-image: url("/assets/front/app/img/svg-icon/flags/gr.svg")
}
.flag-jp {
background-image: url("/assets/front/app/img/svg-icon/flags/jp.svg")
}
.flag-eur, .icon-eur {
  background-image: url("/assets/front/app/img/svg-icon/flags/eur.svg")
}
.flag-th {
  background-image: url("/assets/front/app/img/svg-icon/flags/th.svg")
}
.flag-tw {
  background-image: url("/assets/front/app/img/svg-icon/flags/tw.svg")
}
.flag-za {
  background-image: url("/assets/front/app/img/svg-icon/flags/za.svg")
}
.flag-id {
  background-image: url("/assets/front/app/img/svg-icon/flags/id.svg")
}
.flag-hu {
  background-image: url("/assets/front/app/img/svg-icon/flags/hu.svg")
}
.flag-hk {
  background-image: url("/assets/front/app/img/svg-icon/flags/hk.svg")
}
.flag-ae {
  background-image: url("/assets/front/app/img/svg-icon/flags/ae.svg")
}
.flag-in {
  background-image: url("/assets/front/app/img/svg-icon/flags/in.svg")
}
.flag-gt {
  background-image: url("/assets/front/app/img/svg-icon/flags/gt.svg")
}
.flag-bs {
  background-image: url("/assets/front/app/img/svg-icon/flags/bs.svg")
}
.flag-cl {
  background-image: url("/assets/front/app/img/svg-icon/flags/cl.svg")
}
.flag-lu {
  background-image: url("/assets/front/app/img/svg-icon/flags/lu.svg")
}
.flag-at {
  background-image: url("/assets/front/app/img/svg-icon/flags/at.svg")
}