  @media (max-width: 834px) {
    .scroller-inner .header-alert .banner-inner.webinar {
      padding-top: 240px;
    }
  }
  
  @media (min-width: 1024px) {
    .float-header.header-alert .banner-inner.webinar {
      padding-top:340px
    }
  }