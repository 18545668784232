/*
------------------------------------------------------------------------------- ILLUSTRATED GUIDE

------------------------------------------------------------------------------------------------
*/

.guide-summary {display: block; text-align: center; text-decoration: none; /*background-color: $bg-swoop;*/ @include font-smoothing(on);}
.guide-summary-img {max-width: 720px; margin:0 auto;}

.guide-summary.portrait .guide-summary-img {max-width: 320px;}
// .guide-summary-img img {margin:0} 
.guide-summary-img.guide-summary-img-bordered img {border:4px solid $bg-swoop; background-color: $bg-swoop; margin:0 auto;}
// .guide-summary .cta {padding-top: 30px;}
// .guide-summary .btn {margin-right: 0;}

// .guide-summary-footer {font-size: 1.1em; line-height: 32px; text-align: center; padding: 10px 0; }
// .guide-summary-footer span:before {top:0;}

.pswp__caption__center {text-align: center;}
.pswp__button {border-radius: 0;}

@media screen and (min-width: 45em) {//  720/16
    .guide-summary-footer {line-height: 40px; padding: 20px 0; }
    .guide-summary-footer span {padding-left: 54px;}
}
