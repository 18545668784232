/*
----------------------------------------------------------------------- NEWSLETTER SIGNUP STYLES

------------------------------------------------------------------------------------------------
*/

.newsletter-signup-container {
    opacity: 1;
    transition: opacity 0.2s linear;
}

.newsletter-signup-container.fade-out {
    opacity:0;
}
