/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_forms.scss - FORM ELEMENTS BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  01-03-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

@import "site_variables";

/*
---------------------------------------------------------------------------------- FORM ELEMENTS

------------------------------------------------------------------------------------------------
*/

.form-group {
  clear: both;
  margin-bottom: 15px;
  @include clrfix;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

label {
  font-size: 1em;
  line-height: 1.5;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  margin-bottom: 5px;
  @include font-smoothing(on);
}

input,
textarea,
button,
.btn {
  box-sizing: border-box;
}

.form-control {
  @include font-smoothing(on);
  @include appearance(none);
  padding: 4px 17px;
  margin: 0;
  border: 1px solid $form-input-border;
  color: $text-main;
  background-color: $form-input-bg;
  font-size: 16px;
  line-height: 1.6;
  width: 100%;
  height: 44px;
  outline: none;
  border-radius: 0;
  transition: all 0.3s ease-out;
  border-radius: 0;

  &:focus {
    border-color: $primary;
    background-color: $form-input-focus-bg;
    &::placeholder {
      color: #b9b9b9;
    }
  }
}

.form-control.form-control-xs {
  font-size: 14px;
  line-height: 1.7857;
  height: 27px;
}

.form-control-static {
  margin-bottom: 0;
  padding: 4px 0 0 0;
  font-size: 1.063em;
  line-height: 1.6;
}

textarea.form-control {
  height: auto;
}

// Change placeholder text color
/*.form-control::-webkit-input-placeholder {color: $text-placeholder;}
.form-control:-moz-placeholder {color: $text-placeholder;}
.form-control::-moz-placeholder {color: $text-placeholder;}
.form-control:-ms-input-placeholder {color: $text-placeholder;}*/

.input-group .form-control {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}

.input-group-btn:last-child > .btn,
.input-group-btn:last-child > button {
  //margin-left: -1px;
  margin-right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input.table-input {
  margin: 0;
  max-width: 80px;
  text-align: right;
}
input.input-4-digits {
  max-width: 80px;
}

.form-group-sm label {
  padding-top: 3px;
  font-size: 1.4em;
}
.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 0.875em;
  line-height: 1.5;
}

input[readonly]:focus {
  border-color: $white;
  background-color: $white;
}

textarea {
  // min-height: 144px;
  margin: 0;
  vertical-align: top;
}

input[type="radio"] {
  margin-right: 10px;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0 0 0 0;
  margin: -2px 6px 0 0;
  height: 16px;
  cursor: pointer;
}

select.form-control {
  @include appearance(menulist);
}

select[multiple],
select[size] {
  height: auto;
  @include appearance(none);
}

.checkbox label,
.radio label {
  min-height: 20px;
  margin-bottom: 0;
  padding-top: 0;
  font-weight: 400;
  cursor: pointer;
  float: none;
}

button,
.btn {
  text-transform: uppercase;
  // font-size: 18px;
  line-height: 1;
  @include font-smoothing(on);
  padding: .6em 1.4em;
  margin: 0 20px 22px 0;
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  transition: background-color 100ms linear, color 100ms linear,
    border-color 100ms linear;
  text-decoration: none;
  border-radius: 4px;
  position: relative;
}

button:hover,
.btn:hover,
.btn:focus {
  background-color: $primary-hover;
  border-color: $primary-hover;
  text-decoration: none;
}

.btn-disabled,
.btn-disabled:hover {
  cursor: not-allowed;
  pointer-events: none;
}

.btn-full {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  float: none;
  text-align: center;
  margin-right: 0;
  @media screen and (max-width: 500px) {
    width: auto;
  }
}

.btn-lrg {
  line-height: 2.33333333;
}

// Secondary Buttons
.btn-secondary {
  background-color: transparent;
  border-color: $text-dark;
  color: $primary;
  padding: 10px 20px;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #e66c28;
  border-color: #974213;
  color: #fff;
}

.bg-swoop .btn-secondary {
  border-color: $white;
}

// Tertiary Buttons

.btn-tertiary {
  background-color: transparent;
  border-color: $text-dark;
  color: $text-dark;
}

.btn-tertiary:hover {
  color: $white;
}

// Anchor Buttons
.btn-anchor {
  background-color: $white;
  border-color: $white;
  color: $text-dark;
  font-size: 15px;
  line-height: 1.1;
  padding: 15px 10px;
}


@media screen and (max-width: 719px) {
  .btn-anchor {
    font-size: 13px;
    padding: 5px 10px;
    margin-bottom: 0;
    line-height: unset;
  }
  .anchor-row {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-around;
    padding-bottom: 0;
    li {
      display: inline-block;
      margin: 0 4px;
    }
  }
  .anchor-row-wrapper {
    padding: 7px 0;
    overflow-y: scroll;
    min-width: 100vw;

    div.row {
      width: max-content;
      min-width: 100vw;
    }
  }
}

.btn-anchor:hover,
.btn-anchor:focus {
  background-color: $primary-pale;
  border-color: $primary-pale;
}

// Text Buttons
.btn-text {
  padding: 0 20px 0 0;
  position: relative;
  text-align: center;
  background-color: transparent;
  border: none;
  color: $primary;
}
.btn-text:after {
  position: absolute;
  transition: right 0.1s ease-out;
}
.btn-text:hover,
.btn-text:focus {
  background-color: transparent;
  border: none;
  color: $primary-pale;
}

.btn-down:after,
.btn-up:after {
  position: absolute;
  content: "▼";
  font-size: 1rem;
  top: -1px;
  right: -1px;
}

.btn-up:after {
  content: "▲";
}

table .btn-text:after {
  bottom: 8px;
}

.btn-text:hover:after {
  right: 0;
}

// same dimensions as btn but just text

.btn-link {
  border-color: transparent;
  background-color: transparent;
  color: $primary;
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  border-color: transparent;
  background-color: transparent;
  color: $primary;
}

// Button Size
.btn-sm {
  font-size: 16px;
  line-height: 1.625;
}
.btn-xs {
  font-size: 13px;
  @media screen and (min-width: $break-landscape) {
    //  496/16
    font-size: 14px;
  }
  line-height: 1.4;
  padding: 4px 12px;
}

.btn-inner {
  color: white;
}
.btn-inner:hover {
  text-decoration: none;
}

//.btn-disabled, .btn-disabled:hover, a:hover .btn-disabled {cursor: not-allowed; pointer-events: none; background-color:$bg-grey-pale; border-color:$bg-grey-pale; color:$bg-grey-pale-mid;}

// Fix firefox button height
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

// Button pending
.btn {
  transition: background-color 100ms linear, color 100ms linear,
    border-color 100ms linear, padding 200ms ease-in-out;
}

.btn-pending {
  padding-right: 42px;
  position: relative;
}
.btn-full.btn-pending {
  padding-left: 42px;
} // default padding

.btn-pending:after {
  content: "";
  display: block;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 12px;
  right: 10px;
  background: url("../img/ajax-loader-btn.gif") 0 0 no-repeat;
  opacity: 0;
  transition: opacity 200ms linear;

  @media (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi),
    (min-resolution: 1.25dppx) {
    background-image: url("../img/retina/ajax-loader-btn.gif");
    background-size: 19px 19px;
  }
}

.btn-primary.btn-pending:after {
  background-image: url("../img/ajax-loader-btn-primary.gif");
  @media (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi),
    (min-resolution: 1.25dppx) {
    background-image: url("../img/retina/ajax-loader-btn-primary.gif");
  }
}

.btn-pending-show:after {
  opacity: 1;
}

// Errors

.error label.error {
  color: $danger;
  font-weight: 300;
  font-size: 16px;
}
.note {
  font-size: 1.06em;
}

.error .form-control {
  border-color: $danger;
  border-width: 2px;
}
.valid .form-control {
  border-color: $success;
  border-width: 2px;
}
//.error label {color:$danger;}

.form-container {
  @include clrfix;
}
.form-container-result {
  display: none;
}

// AJAX Loaders

.ajax-spinner {
  width: 19px;
  height: 19px;
  background: url("../img/ajax-loader.gif") 0 0 no-repeat;
  display: none;

  @media (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi),
    (min-resolution: 1.25dppx) {
    background-image: url("../img/retina/ajax-loader.gif");
    background-size: 19px 19px;
  }
}

.ajax-spinner-inline {
  width: 19px;
  height: 10px;
  background: url("../img/ajax-loader-inline.gif") 0 0 no-repeat;
  display: none;

  @media (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi),
    (min-resolution: 1.25dppx) {
    background-image: url("../img/retina/ajax-loader-inline.gif");
    background-size: 16px 4px;
  }
}

.ajax-spinner.ajax-spinner-show {
  display: block;
}
.ajax-spinner-inline.ajax-spinner-show {
  display: inline-block;
}

/*
----------------------------------------------------------------------------------------- LAYOUT

------------------------------------------------------------------------------------------------
*/

// This will be a mixin one day, until then check against enhanced.scss
@media screen and (min-width: 45em) {
  //  720/16

  .form-horizontal label {
    width: 25%;
    float: left;
    padding-top: 6px;
    padding-right: 16px;
    text-align: right;
  }
  .form-horizontal .form-control {
    width: 75%;
  }
  .form-horizontal select.form-control {
    width: 100%;
  }
  .form-horizontal .select {
    width: 75%;
    float: right;
  }

  .form-horizontal .error-msg,
  .form-horizontal .label-offset,
  .form-horizontal label.error {
    padding-left: 25%;
    padding-right: 8px;
    width: 100%;
    text-align: left;
  }

  .form-horizontal .checkbox,
  .form-horizontal .radio {
    min-height: 27px;
    padding-top: 7px;
    margin-top: 0;
    margin-bottom: 0;
    width: 75%;
    margin-left: 25%;
  }

  .form-horizontal .checkbox label,
  .form-horizontal .radio label {
    float: none;
    width: auto;
    padding-top: 0;
  }

  .form-horizontal .control-checkboxes,
  .form-horizontal .control-radios {
    width: 65%;
    float: right;
  }

  .form-horizontal .form-submit {
    padding-left: 25%;
  }

  .form-horizontal .rating,
  .form-horizontal .rating-static {
    padding-top: 7px;
  }

  .checkbox-inline,
  .radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
  }
}

/*
---------------------------------------------------------------------- CHOSEN DROPDOWN OVERRIDES

------------------------------------------------------------------------------------------------
*/

.chosen-container {
  /*margin-bottom:10px;*/
  font-size: 20px;
  @include font-smoothing(on);
}

.chosen-container a {
  font-weight: 300;
}

.chosen-container-single .chosen-single {
  background: $form-input-bg;
  color: $text-main;
  font-weight: 300;
  border: 1px solid $form-input-border;
  overflow: visible;
  height: 44px;
  padding-left: 14px;
  border-radius: 0;
}

.chosen-container-multi .chosen-choices {
  background-color: $form-input-bg;
  color: $text-pale;
  border: 1px solid $form-input-border;
  height: 44px;
  font-size: 1em;
  padding-left: 14px;
  border-radius: 0;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  height: 44px;
}

.chosen-container-single .chosen-default {
  color: $text-placeholder;
}

.chosen-container-single .chosen-single:hover,
.chosen-container-active.chosen-with-drop .chosen-single {
  background-color: $form-input-focus-bg;
}

.chosen-container-active.chosen-with-drop .chosen-single div:before {
  background-position: -204px -25px;
}

.chosen-container-active .chosen-single,
.chosen-container-active .chosen-choices {
  border: 1px solid $primary;
}

.chosen-container-single .chosen-single span {
  padding-top: 1px;
}

.chosen-container-single .chosen-single div {
  width: 35px;
  height: auto;
}
.chosen-container-single .chosen-single div:before {
  @include icon();
  width: 16px;
  height: 11px;
  position: absolute;
  top: 17px;
  left: 9px;
  background-position: -204px 0;
}
.chosen-container-single .chosen-single div b {
  display: none;
}

.chosen-container-single .chosen-single abbr {
  @include icon();
  width: 18px;
  height: 18px;
  top: 12px;
  right: 36px;
  background-position: -220px 4px;
  opacity: 0.5;
}
.chosen-container-single .chosen-single abbr:hover {
  background-position: -220px 4px;
  opacity: 1;
}

.chosen-container-multi .chosen-choices li.search-choice {
  margin-top: 5px;
  padding: 6px 20px 6px 8px;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  right: 6px;
  top: 5px;
}
.chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close:before {
  @include icon();
  width: 16px;
  height: 16px;
  top: 12px;
  right: 36px;
  background-position: -220px 4px;
  opacity: 0.5;
}
.chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close:hover:before {
  background-position: -220px 4px;
  opacity: 1;
}

.chosen-container .chosen-drop {
  background-color: $form-input-bg;
}
.chosen-container-active .chosen-drop {
  border: 1px solid $form-input-border;
}

.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
}
.chosen-container .chosen-results li.highlighted {
  background-color: $primary;
}

.error .chosen-container-single .chosen-single,
.error .chosen-container-multi .chosen-choices {
  border-color: $danger;
  border-width: 2px;
}

.valid .chosen-container-single .chosen-single,
.valid .chosen-container-multi .chosen-choices {
  border-color: $success;
  border-width: 2px;
}

.error .chosen-container-single .chosen-single abbr,
.valid .chosen-container-single .chosen-single abbr {
  top: 9px;
}

.error .chosen-container-single .chosen-single div:before,
.valid .chosen-container-single .chosen-single div:before {
  top: 14px;
}

.error .chosen-container-single .chosen-single span,
.valid .chosen-container-single .chosen-single span {
  padding-top: 0;
}

/*  [SCS-1764] Input group */

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group-btn .btn {
  margin: 0;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-group-remember {
  .checkbox-label {
    display: inline-block;

    margin: 0;
    padding-left: 0;
    padding-right: 33px;
    width: auto;

    font-size: 1em;
    .checkmark {
      top: 0.4em;
      right: 0.5em;
      left: auto;

      height: 15px;
      width: 15px;

      &::after {
        left: 5px;
        top: 1.5px;
        width: 4px;
        height: 9px;
      }
    }
  }
}

/*
------------------------------------------------------------------------------- ENQUIRY /  FORMS

------------------------------------------------------------------------------------------------
*/

.cta {
  padding-top: 8px;
}

.enquiry-intro {
  @include col-med;
  padding: 1rem 0 0;
}

.content-tel {
  font-size: 1.25em;
}
.content-tel a:hover {
  text-decoration: none;
}

.enquiry-form {
  @include col-med;
  padding: 30px;
  background-color: $bg-grey-paler;
}
.form-block {
  width: 100%;
}
.field {
    position: relative;
}

.field i {
    left: 2px;
    top: 4px;
    position: absolute;
    height: 32px;
    width: 32px;
    opacity: 0.7;
    text-align: center;
    line-height: 42px;
    pointer-events: none;
}

.enquiry-input__icon {
  margin-bottom: -0.6rem;
  padding: 1.9rem;
  position: relative;
  top: -38px;
  left: 3px;
  opacity: 0.5;
  display: inline-block;
}

.name-icon {
  background: url(/assets/front/app/img/svg-icon/user.svg);
  background-repeat: no-repeat;
}
.email-icon {
  background: url(/assets/front/app/img/svg-icon/email.svg);
  background-repeat: no-repeat;
}
.phone-icon {
  background: url(/assets/front/app/img/svg-icon/phone.svg);
  background-repeat: no-repeat;
}
.chevron-icon {
  background: url(/assets/front/app/img/svg-icon/chevron.svg);
  background-repeat: no-repeat;
}


#firstName.form-control,
#lastName.form-control,
#email.form-control,
#emailconfirm.form-control,
#refer-tel.form-control,
#tel.form-control,
#friend.form-control {
  padding: 4px 17px 4px 35px;
}

.form-submit {
  padding-top: 12px;
}
.form-submit .btn {
  margin-bottom: 0;
}
.form-submit .btn:last-child {
  margin-right: 0;
}

.alert {
  background-color: $bg-grey-paler;
  border: 1px solid $bg-grey-pale;
  padding: 12px 16px 0 16px;
  margin-bottom: 20px;
  position: relative;
}
.alert .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 6px 10px;
  font-size: 1.25em;
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #000;
}
.alert .btn-close:hover {
  color: #333;
}

.alert p {
  margin-bottom: 12px;
}
.alert .btn {
  margin-right: 10px;
}

.alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger {
  //color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert h4 {
  margin-bottom: 12px;
}

.enquiry-form-wrapper h3 {
  padding-top: 24px;
  padding-bottom: 18px;
  border-top: 4px solid #272727;
  border-bottom: 1px solid #272727;
  margin-bottom: 36px;
}
.split-input div.form-group {
    width: 48%;
}

.js-enquiry-form button[type="submit"]:disabled {
    background-color: rgba(113,128,150,1);
    border-color: #4a5568;
    cursor: not-allowed;
}

