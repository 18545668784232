.trip-comparison {

    .trip-markup {

        ul {
            display: flex;
            flex-direction: column;
            list-style-type: disc;
            padding-top: 0.5rem;
            padding-left: 1rem;
            font-size: 0.9rem;
            line-height: 1.3;
            > li {
                padding-bottom: 0.5rem;
            }
        }

        p {
            font-size: 0.9rem;
            line-height: 1.3;
        }

        b, strong {
            font-family: inherit;
            font-weight: normal;
        }
    }
    .t-and-c > p {
        font-size: 0.95rem;
    }
    .text-green-500 {
        color: #48bb78;
    }

    .trip-addon {
        &:hover {
            > img {
                opacity: 1;
            }
        }
    }

    .react-tooltip {
        max-width: 500px;
    }

    @media (min-width: 1040px) {
        div.flex-shrink-0.w-3\/12.mx-1 {
            min-width: 20rem;
        }
    }
}

.transition-height {
    transition: height 0.5s;
}

.fix-content {
    p {
        font-size: inherit;
        line-height: inherit;
        margin: 0;
    }
}
#compare-table {
    min-width: 800px;
    @media (min-width: 1024px) {
        min-width: 900px;
    }
    @media (min-width: 768px) {
        min-width: 1000px;
    }
}
