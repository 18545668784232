/*
-------------------------------------------------------------------------- BANNERS / PAGE INTROS

------------------------------------------------------------------------------------------------
*/

.banner {
  color: $white;
  text-align: center;
  background-color: $bg-swoop;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  padding: 0;
}
.offer {
  .banner-content {
    padding-bottom: 36px;
    p:nth-last-child(1) {
      margin-top: -3%;
    }
  }
}
.banner-inner {
  position: relative;
  &--location {
    background: linear-gradient(0.1turn, #041e42, #1d3455 150%);
  }
}
.banner h1 {
  color: $white;
}

.banner-content {
  @include col-wide;
}
.banner-content p {
  @include text-larger;
  text-shadow: 0px 0px 15px $text-dark;
}


.banner .btn {
  margin: 0;
}

.banner:before,
.banner-inner:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #0b0c18;
  opacity: 0.5;
}

.banner-inner:before {
  background-color: transparent;
  opacity: 1;
  background: url('../img/banner-fade-overlay.png') 0 0 repeat-x;
}

.banner-content-page {
  background-color: $bg-swoop-secondary;
}

.banner-content-page:before,
.banner-content-page .banner-inner:before {
  display: none;
}

.banner-content-page .banner-inner {
  padding-top: 40px;
  padding-top: 40px;
}

.banner-content-page .banner-inner {
  padding-top: 20px;
  padding-bottom: 40px;
}

// (min-width: $break-medium) removed 
@media screen and (min-width: 833px) {
  //  720/16
  .banner-inner:before {
    opacity: 1;
  }
  .float-header .banner-inner {
    padding-top: 270px; // 90 + height of header
  }

  .float-header .banner-simple {
    padding-top: 280px; // 90 + height of header
  }
}

@media screen and (min-width: 62em) {
  //  992/16

  /* ---------------------- BANNERS / CAROUSELS */
  .float-header .banner-inner {
    padding-top: 290px; // 90 + height of header
  }
  .float-header .banner-simple {
    padding-top: 220px; // 
  }
} // end min-width: 992px

@media screen and (min-width: 1024px) {
  //  992/16

  /* ---------------------- BANNERS / CAROUSELS */
  .float-header .banner-simple {
    padding-top: 160px; // 
  }
} // end min-width: 992px