// css to adds placeholder height for lazyload images

img {
  min-height: 50px;
  font-size: 14px;
  text-align: center;
}
figure.w-3\/12 img, figure.w-4\/12 img {
  min-height: 120px;
  background-color: #ddd;
}
.guide-summary.portrait .guide-summary-img, .content-block-img, div.illustrated-guide { 
  min-height: 100px;
}
img, .avatar-row img, .guide-summary.portrait .guide-summary-img, .content-block-img img{
background-color: $bg-grey-paler;
}
footer img,  header img, img.advert-card-logo, .avatar-img img, .summary-card-img img, .gm-style img, img.add-on-activity-icon, .js-lightbox img {
background-color: transparent;
}
.gm-style img{
  min-height: auto;
}