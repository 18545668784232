.icon-amex:before {
  content: "\e900";
  color: #fff;
}
.icon-mastercard .path1:before {
  content: "\e901";
  color: rgb(255, 255, 255);
}
.icon-mastercard .path2:before {
  content: "\e902";
  margin-left: -1.67578125em;
  color: rgb(255, 95, 0);
}
.icon-mastercard .path3:before {
  content: "\e903";
  margin-left: -1.67578125em;
  color: rgb(235, 0, 27);
}
.icon-mastercard .path4:before {
  content: "\e904";
  margin-left: -1.67578125em;
  color: rgb(247, 158, 27);
}
.icon-visa .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}
.icon-visa .path2:before {
  content: "\e906";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path3:before {
  content: "\e907";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path4:before {
  content: "\e908";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path5:before {
  content: "\e909";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-arrow-down:before {
  content: "\e90e";
}
.icon-arrow-d:before {
  content: "\e90d";
}
.icon-arrow-left:before {
  content: "\e90f";
}
.icon-arrow-r-top:before {
  content: "\e910";
}
.icon-arrow-r:before {
  content: "\e911";
}
.icon-arrow-right:before {
  content: "\e912";
}
.icon-arrow-u:before {
  content: "\e97f";
}
.icon-facebook:before {
  content: "\e979";
}
.icon-instagram:before {
  content: "\e97a";
}
.icon-pinstrast:before {
  content: "\e97b";
}
.icon-tweeter:before {
  content: "\e97c";
}
.icon-whats-app:before {
  content: "\e97d";
}
.icon-youtube:before {
  content: "\e97e";
}
.icon-alert:before {
  content: "\e90a";
}
.icon-ambulance:before {
  content: "\e90b";
}
.icon-anchour:before {
  content: "\e90c";
}
.icon-base-camp-lux:before {
  content: "\e913";
}
.icon-base-camp:before {
  content: "\e914";
}
.icon-bear:before {
  content: "\e915";
}
.icon-bed:before {
  content: "\e916";
}
.icon-bird:before {
  content: "\e917";
}
.icon-bus:before {
  content: "\e918";
}
.icon-camping:before {
  content: "\e919";
}
.icon-chat:before {
  content: "\e91a";
}
.icon-chevron-down:before {
  content: "\e91b";
}
.icon-chevron-r:before {
  content: "\e91c";
}
.icon-chevron-right:before {
  content: "\e91d";
}
.icon-chevron2:before {
  content: "\e91e";
}
.icon-church:before {
  content: "\e91f";
}
.icon-climbing:before {
  content: "\e920";
}
.icon-close:before {
  content: "\e921";
}
.icon-confused:before {
  content: "\e922";
}
.icon-covid:before {
  content: "\e923";
}
.icon-cx-skiing:before {
  content: "\e924";
}
.icon-date:before {
  content: "\e925";
}
.icon-dog-sledding:before {
  content: "\e926";
}
.icon-dont-know:before {
  content: "\e927";
}
.icon-ecocamp:before {
  content: "\e928";
}
.icon-email-2:before {
  content: "\e929";
}
.icon-email:before {
  content: "\e92a";
}
.icon-eye:before {
  content: "\e92b";
}
.icon-fishing:before {
  content: "\e92c";
}
.icon-food:before {
  content: "\e92d";
}
.icon-glacier-trekking:before {
  content: "\e92e";
}
.icon-glass:before {
  content: "\e92f";
}
.icon-globe:before {
  content: "\e930";
}
.icon-guide:before {
  content: "\e931";
}
.icon-hand:before {
  content: "\e932";
}
.icon-hauled-sled:before {
  content: "\e933";
}
.icon-history:before {
  content: "\e934";
}
.icon-horse-riding:before {
  content: "\e935";
}
.icon-hotel:before {
  content: "\e936";
}
.icon-ice:before {
  content: "\e937";
}
.icon-image .path1:before {
  content: "\e938";
  color: rgb(0, 0, 0);
}
.icon-image .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.icon-image .path3:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-image .path4:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-info:before {
  content: "\e93c";
}
.icon-kayaking:before {
  content: "\e93d";
}
.icon-light:before {
  content: "\e93e";
}
.icon-lock:before {
  content: "\e93f";
}
.icon-lux_hotel:before {
  content: "\e940";
}
.icon-map-marker:before {
  content: "\e941";
}
.icon-map:before {
  content: "\e942";
}
.icon-market:before {
  content: "\e943";
}
.icon-menu:before {
  content: "\e944";
}
.icon-mountain-biking:before {
  content: "\e945";
}
.icon-mountain-peak:before {
  content: "\e946";
}
.icon-mountaineering:before {
  content: "\e947";
}
.icon-multi:before {
  content: "\e948";
}
.icon-museum:before {
  content: "\e949";
}
.icon-paddle-boarding:before {
  content: "\e94a";
}
.icon-payment:before {
  content: "\e94b";
}
.icon-penguin:before {
  content: "\e94c";
}
.icon-phone:before {
  content: "\e94d";
}
.icon-photography:before {
  content: "\e94e";
}
.icon-plain-2:before {
  content: "\e94f";
}
.icon-plain:before {
  content: "\e950";
}
.icon-play .path1:before {
  content: "\e951";
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.icon-play .path2:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-play .path3:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-price:before {
  content: "\e954";
}
.icon-puma:before {
  content: "\e955";
}
.icon-question-2:before {
  content: "\e956";
}
.icon-rafting:before {
  content: "\e957";
}
.icon-refugio:before {
  content: "\e958";
}
.icon-road-trip:before {
  content: "\e959";
}
.icon-sailing:before {
  content: "\e95a";
}
.icon-science:before {
  content: "\e95b";
}
.icon-scuba:before {
  content: "\e95c";
}
.icon-seal:before {
  content: "\e95d";
}
.icon-search:before {
  content: "\e95e";
}
.icon-sextant:before {
  content: "\e95f";
}
.icon-ship-2:before {
  content: "\e960";
}
.icon-ship-lg:before {
  content: "\e961";
}
.icon-ship-md:before {
  content: "\e962";
}
.icon-ship:before {
  content: "\e963";
}
.icon-sign:before {
  content: "\e964";
}
.icon-skiing:before {
  content: "\e965";
}
.icon-snorkelling:before {
  content: "\e966";
}
.icon-snow-shoeing:before {
  content: "\e967";
}
.icon-snow:before {
  content: "\e968";
}
.icon-snowmobiling:before {
  content: "\e969";
}
.icon-speach:before {
  content: "\e96a";
}
.icon-star-full:before {
  content: "\e96b";
}
.icon-star-half:before {
  content: "\e96c";
}
.icon-star:before {
  content: "\e96d";
}
.icon-suitcase:before {
  content: "\e96e";
}
.icon-swoop:before {
  content: "\e96f";
}
.icon-team:before {
  content: "\e970";
}
.icon-thanks:before {
  content: "\e971";
}
.icon-trekking:before {
  content: "\e972";
}
.icon-urgent:before {
  content: "\e973";
}
.icon-user:before {
  content: "\e974";
}
.icon-user2:before {
  content: "\e975";
}
.icon-vineyard:before {
  content: "\e976";
}
.icon-wildlife:before {
  content: "\e977";
}
.icon-zodiac_excursions:before {
  content: "\e978";
}
