html, .menu-container, .nav-scroller {
  height: 100%;
}

// .nav-scroller {
// overflow-y: scroll;
// }

.nav-scroller,
.nav-scroller-inner {
position: relative;
}

.menu-container {
position: relative;
overflow-x: hidden;
}

.mp-pusher {
position: relative;
left: 0;
height: 100%;
}

.mp-menu {
position: absolute;
top: 0;
left: 0;
z-index: 1;
width: 320px;
height: 100%;
-webkit-transform: translate3d(-100%, 0, 0);
-moz-transform: translate3d(-100%, 0, 0);
transform: translate3d(-100%, 0, 0);
overflow-y: auto;
}

.mp-level {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: #FFF;
z-index: 1;
-webkit-transform: translate3d(-100%, 0, 0);
-moz-transform: translate3d(-100%, 0, 0);
transform: translate3d(-100%, 0, 0);
}

/* overlays for pusher and for level that gets covered */
.mp-pusher::after,
.mp-level::after,
.mp-level::before {
position: absolute;
top: 0;
right: 0;
width: 0;
height: 0;
content: '';
opacity: 0;
}

.mp-pusher::after,
.mp-level::after {
background: rgba(5, 13, 45, 0.3);
-webkit-transition: opacity 0.2s;
-moz-transition: opacity 0.2s;
transition: opacity 0.2s;
}

.mp-level::after {
z-index: -1;
}

.mp-pusher.mp-pushed::after,
.mp-level.mp-level-overlay::after {
width: 100%;
height: 100%;
opacity: 1;
/* -webkit-transition: opacity 0.3s;
-moz-transition: opacity 0.3s;
transition: opacity 0.3s; */
}
.mp-level.mp-level-overlay {
cursor: pointer;
}
.mp-level.mp-level-overlay.mp-level::before {
width: 100%;
height: 100%;
background: transparent;
opacity: 1;
}

.mp-pusher,
.mp-level {
-webkit-transition: all 0.5s;
-moz-transition: all 0.5s;
transition: all 0.5s;
}

/* overlap */
.mp-overlap .mp-level.mp-level-open {
box-shadow: 1px 0 2px rgba(0,0,0,0.2);
-webkit-transform: translate3d(-40px, 0, 0);
-moz-transform: translate3d(-40px, 0, 0);
transform: translate3d(-40px, 0, 0);
}

/* First level */
.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
box-shadow: none;
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}

/* cover */
.mp-cover .mp-level.mp-level-open {
-webkit-transform: translate3d(0, 0, 0);
-moz-transform: translate3d(0, 0, 0);
transform: translate3d(0, 0, 0);
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
-webkit-transform: translate3d(-100%, 0, 0);
-moz-transform: translate3d(-100%, 0, 0);
transform: translate3d(-100%, 0, 0);
}
/* back button */
.mp-back {
background: #e66c28;
outline: none;
color: #fff;
text-transform: uppercase;
letter-spacing: 1px;
font-weight: 700;
display: block !important;
font-size: .9rem;
font-family: sans-serif;
line-height: 1;
padding: 0.7em 1em 0.7em 2.5em;
position: relative;
box-shadow: inset 0 1px rgba(0,0,0,0.1);
-webkit-transition: all 0.3s;
-moz-transition: all 0.3s;
transition: all 0.3s;
}
.mp-menu ul li a.mp-back{
padding: 1rem 2.5rem;
}
.mp-menu ul li a.mp-back:hover {
color: #fff;
background: #c35112;
text-decoration: none;
padding-left: 2em;
}
.mp-back::after {
position: absolute;
content: "‹";
left: 20px;
top: 6px;
bottom: 0;
font-size: 1.8rem;
line-height: 1.1;
opacity: 0.7;
}

.mp-menu .mp-level.mp-level-overlay > .mp-back,
.mp-menu .mp-level.mp-level-overlay > .mp-back::after {
background: transparent;
box-shadow: none;
color: transparent;
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
/* We'll show the first level only */
.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
display: none;
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
display: block;
}

/* custom css */
.mp-menu ul li {
width: 100%;
}
.mp-menu ul li:hover {
background-color: rgba(237,242,247);
}
.mp-menu ul li a{
padding: .5rem 1rem;
}
.mp-menu ul li a.arrow{
padding-top: 1rem;
padding-bottom: 1rem;
padding-left: 1.5rem;
}
.menu li:hover,
.menu li:focus,
.menu li:active {
  background-color: transparent;
}
.menu li:hover .top-button,
.menu li:focus .top-button,
.menu li:active .top-button{
  background-color: #e66c28;
}

// .menu li:hover .dropdown,
// .menu li:focus .dropdown,
// .menu li:active .dropdown {
//   display: flex;
//   }
.menu li.active .dropdown {
  display: flex;
  }


  /* Start Single Section Menu */
  .dropdown {
    display: none;
    min-height: 500px;
    /* max-height: 600px; */
    overflow-y:scroll;
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: #ffffff;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    text-align: left;
    margin: 0;
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    /* animation-delay: .05s; */
  }

  .chevron-icon {
    background: url(../icons/chevron-wht.svg);
    background-repeat: no-repeat;
  }

  .dim {
    z-index: 1;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-y: auto;
    background: rgba(5, 13, 45, 0.3);
  }

  header .logo object {
    height: 50px;
    max-width: 450px;
  }

  #header-cta {
    margin: 0;
    text-align: center;
    max-width: 240px;
  }
  #mp-menu {
    display: none;
  }

  /* hover arrows */
  a.arrow {
    padding-left: 4px;
    text-decoration: none;
    transition: margin .3s ease;
  }
  a.arrow:hover {
    margin-left: 1rem;
  }
  a.arrow:hover, .menu li.dp-open a:hover {
    color: #e66c28;
  }
  .arrow {
      width: 100%;
      position: relative;
      display: inline-block;
      transition: transform .25s ease;
  }
  .arrow::before {
      pointer-events: none;
      content: "→";
      opacity: 0;
      position: absolute;
      /* top: -2px; */
      left: 0;
      display: block;
      transform: translateX(-1.5em) translateZ(0);
      transition: transform .25s ease, opacity .25s ease;
  }
  a:hover .arrow::before, .arrow:hover::before {
      transform: translateX(-1em) translateZ(0);
      opacity: 1;
  }

  /* tablet Nav fix */
  @media (max-width: 834px) {
    .mobile-expand {
      width: 100%;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #2c5282;
    }
    #main-nav, .desktop__hide, .tel svg {
      display: none;
    }
    .mobile__show, .mobile-trigger, #mp-menu {
      display: block;
    }
  }


  /**
  * ----------------------------------------
  * animation fade-in-top
  * ----------------------------------------
  */
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
      opacity: 0.9;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
      opacity: 0.9;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
