/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_tables.scss - TABLE ELEMENT BASE STYLES
------------------------------------------------------------------------------------------------
  Created:  28-07-2016
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

@import "site_variables";


/*
----------------------------------------------------------------------------------------- TABLES

------------------------------------------------------------------------------------------------
*/

.table-responsive-foot {padding:24px 12px; font-size: 0.875em; line-height: 1.2; display: block; text-align: center; }

.table-responsive {overflow:auto; }
.table-responsive.table-scroll {max-height:500px; overflow:auto; border: 1px solid #ccc;}

table {width: 100%!important; clear:both;}

.table tbody {border: 1px solid $text-dark;}
.table-responsive {padding-right:1px; }
.table-responsive.table-scroll {padding-right:0;}
.table-responsive.table-scroll table tbody {border:none;}

.table span {font-size:1em; line-height: 1.2; padding: 5px 0; display:block;}
.table div span {display:inline;}
.table span span {font-size:1em; line-height: 1.2; padding: 0; display:inline;}
.table tr th {text-align: left; font-weight:300; }

.table .right {text-align: right;}

.table p {margin-bottom:0;}
.table img {margin:0;}
table tr td {padding: 12px; vertical-align: top;}
.table tr td {padding: 2px 12px 2px 12px; }

.table tfoot tr {border-top:1px solid $text-dark; }

.table .btn {margin-bottom:0; margin-right:0;}

// Make table work on mobile
.table thead {display: none;}
.table tr {display: block; border-bottom: 1px solid $border-grey-pale;}
.table td {display: block; text-align: right; border-bottom: 1px dotted $border-grey-pale;}
.table td:last-child {border-bottom: 0;}
.table td:before {content: attr(data-label); float: left; font-size: 0.875em; line-height: 1.2em; padding: 5px 0; font-weight:600;}

.table tfoot td {text-align: left;}

.table .cta {padding-top:4px; padding-bottom:4px;}

.table .even td {border-color: $border-grey-pale; background-color: $bg-grey-paler;}

.table .title td {background-color: $bg-grey-mid; color:$white;}
.table .title td span {/*font-size: 2.8em;*/ @include font-smoothing(on); padding-top:2px; padding-bottom: 2px;}

.table tr td.deal-badge-cell {padding-top:7px; padding-bottom:7px;}
.table tr td.deal-badge-cell:empty {display:none;}
.table tr.title td {text-align:left; padding-top:7px; padding-bottom:7px;}

// Remove labels on mobile

.table.table-no-mobile-labels td {text-align: left;}
.table.table-no-mobile-labels td:before {display: none;}

// sticky header
.table thead.sticky-header th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
}



/* ---------------------- BREAK POINT */

@media screen and (min-width: 45em) {//  720/16

  .table thead {display: table-header-group;}

  .table tr {display: table-row;}
  .table td {display: table-cell; text-align: left; border-bottom:none;}
  .table td:before {display:none;}
  .table tr {border-bottom: none;}
  table tr th {padding: 14px 14px;}

  table tr td,
  .table tr td {
    padding: 16px 14px;
  }

  .table tr th:first-child, table tr td:first-child  {padding-left:12px;}
  .table tr th:last-child, table tr td:last-child  {padding-right:12px;}

  .table .cta {padding-top:0; padding-bottom:0;}

  .table .col-date {width:200px;}
  .table .col-cta { width:250px;}
  .table .col-cta .cta {float:right;}

  // Make table container scroll horizontally if there is too many columns
  .table-responsive{width: 100%; overflow-y: hidden; -ms-overflow-style: -ms-autohiding-scrollbar; min-height: .01%; overflow-x: auto; clear:both;}
  .table-responsive table {
    width: 100%;
    max-width: 100%;
  }

  .deal-badge,
  .table .deal-badge{
    font-size:1em!important; line-height:1; display:block; padding-right:12px!important;
  }

  .deal-badge span,
  .table .deal-badge span,
  .trips-table-cruise .table span.deal-badge span {
    font-size:0.563em; display:block;
  }

  .table tr td.deal-badge-cell {width:130px; padding-top:10px; padding-bottom:8px;}
  .table tr td.deal-badge-cell:empty {display: table-cell;}
  .table tr.title td {padding-top:12px; padding-bottom:12px;}

  // Cruise modifier

  .trips-table-cruise .table thead {display: table-header-group; background-color: $white;}

  .trips-table-cruise .table thead th {height: 110px;}
  .trips-table-cruise .table thead div { transform: translate(10px, 40px) rotate(315deg); width: 80px; position: relative; bottom: 24px; right: 10px; font-weight: bold;}

  .trips-table-cruise .table span {font-size:0.938;}
  .trips-table-cruise .table span span {font-size:0.938;}
  .trips-table-cruise .table .title td span {font-size: 1em;}
  .trips-table-cruise .btn {font-size:0.938; text-align:center;}
  .trips-table-cruise .table .btn-text:after {bottom:5px;}

  .trips-table-cruise .col-date {width:130px;}

  .trips-table-cruise .table td,
  .trips-table-cruise .table .even td {
    border-right:1px solid $bg-grey-mid;
    background-clip: padding-box;
  }

  .trips-table-cruise .table td.deal-badge-cell,
  .trips-table-cruise .table .even td.deal-badge-cell,
  .trips-table-cruise .table td.col-cta,
  .trips-table-cruise .table .even td.col-cta,
  .trips-table-cruise .table .title td,
  .trips-table-cruise .table .title.even td {
    border-right:none;
  }

  .trips-table-cruise .table .col-cta { width:120px;}


}// end min-width: 720px


@media screen and (min-width: 60em) {//  960/16
  .table-responsive.table-scroll {max-height:642px;}

}// end min-width: 960px
