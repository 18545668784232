.content-block .content-block-img {
  margin-bottom: 3%;
}

/* --------------------------------------------------------------------------------- BREAK - PHONE */

@media screen and (min-width: 28em) {
  //  460/16
} // end min-width: 460px

/* --------------------------------------------------------------------------------- BREAK - TABLET */

@media screen and (min-width: $break-landscape) {
  //  496/16

  /* ---------------------- BANNERS / CAROUSELS */

  .hero-img .row {
    position: relative;
  }
  .hero-caption {
    padding-top: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translate(0, -50%);
  }
} // end min-width: 496px
// (min-width: $break-medium) removed 
@media screen and (min-width: 833px) {
  /* ---------------------- TEXT DEFAULTS */

  h1 {
    font-size: 3.7rem;
  }
  h2 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.4rem;
  }

  p,
  ul,
  ol,
  dl {
    font-size: 1.18rem;
  }

  .page-title {
    padding-top: 3rem;
  }

  .quote-content p, .press-quote-card .quote-content p {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: $break-tablet) {
  //  720/16
  /* ---------------------- LAYOUT */

  .row {
   max-width:1184px; 
   margin:0 auto;
  } 
  .row,
  .header .row {
    padding: 0 32px;
  }
  .row .row {
    padding-left: 0;
    padding-right: 0;
  }

  .article-content {
    /*@include span(8 of 12);*/
    width: 66.6666%;
    float: left;
  }
  .side-bar {
    /*@include span(3 of 12 last);*/
    width: 33.3333%;
    float: left;
  }

  /* ---------------------- FOOTER */

  .footer-nav {
    clear: both;
  }
  .footer-accreditation img {
    max-height: 90px;
  }

  /* ---------------------- BANNERS / CAROUSELS */

  .banner + .more-content:before,
  .banner + .more-content:after {
    display: block;
  }

  .hero-img,
  .hero-img .row {
    min-height: 380px;
  }

  .quote p {
    padding: 0 100px;
  }
  // .quote-inline p {
  //   padding: 0 20px;
  // }

  .quote-no-avatar {
    padding-top: 80px;
  }

  .quote-marks .quote-content:before,
  .quote-marks .quote-content:after {
    display: block;
  }

  .gallery-wrapper .row {
    max-width: 900px;
  }
  #vessel-wrapper.gallery-wrapper .row {
    max-width: 1180px;
  }
  // .review-summary-split {
  //   @include clrfix;
  // }
  // .review-summary-split .quote {
  //   width: 50%;
  //   float: left;
  //   padding-top: 30px;
  // }
  // .review-summary-split .quote:first-child {
  //   border-bottom: none;
  //   margin-bottom: 0;
  // }
  // .review-summary-split .quote:first-child + div {
  //   border-left: 1px solid $white;
  //   border-top: none;
  //   padding-top: 30px;
  // }

  // .review-summary-split .quote:only-child {
  //   margin-left: 25%;
  // } // center if only one

  .review-summary-inline {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 42px;
  }
  .row .review-summary-inline {
    padding-left: 32px;
    padding-right: 32px;
  }

  .review-summary-inline .quote {
    @include clrfix;
  }
  .review-summary-inline .quote p {
    margin-bottom: 10px;
  }
  .review-summary-inline .quote .quote-desc p {
    margin-bottom: 0;
  }

  .review-summary-inline .avatar-img {
    float: left;
    width: 90px;
    margin-bottom: 0;
  }

  .review-summary-inline blockquote {
    margin-left: 120px;
  }

  .review-summary-inline .quote-content,
  .review-summary-inline .quote-desc {
    text-align: left;
  }

  .review-summary-inline .quote-desc {
    margin-bottom: 0;
  }

  // .about-ship-wrapper {
  // }
  .about-ship-wrapper .content-block-content ul {
    margin-bottom: 0;
  }
  .about-ship-wrapper .content-block-content li {
    padding-bottom: 6px;
  }

  /* ---------------------- BLOCKS */

  .page-intro-with-review .page-intro-content {
    width: 50%;
    float: left;
    padding-right: 32px;
  }
  .page-intro-with-review .page-intro-quote {
    width: 50%;
    float: left;
  }
  .page-intro-with-review .quote-inline {
    border-left: 1px solid $text-dark;
    padding-left: 32px;
  }

  .content-block {
    @include clrfix;
  }
  .content-block .content-block-img {
    width:49%; 
    float:left;
    padding: 0;
    margin: 0 2% 2% 0;
  }
  .content-block .content-block-content {
    width:49%; float:right;
  }
  .content-block .content-block-heading {
    margin-bottom: 20px;
  }
  .content-block-heading > h2{
    text-align: center;
  }

  /* ---------------------- BLOCK MODIFIERS */

  .content-block.bl-full-width .content-block-img,
  .content-block.bl-full-width .content-block-content {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-right: 0;
  }
  .content-block.bl-full-width .content-block-content, .content-block.bl-full-width .content-block-heading.content-block-heading-with-sub > h3 {
    @include col-med;  
  }

  .content-block.bl-wrap .content-block-content {
    width: auto;
    float: none;
    margin-left: 0;
    margin-right: 0;
    ul {
      list-style-position: inside;
    }
  }

  .content-block.bl-img-left.bl-wrap .content-block-img {
    margin-left: 0;
  }

  /* ---------------------- NAVIGATION ROW */

  .nav-row-card .summary-card-heading h3 {
    font-size: 2.6em;
  }

  /* ---------------------- TRIP CONTENT */

  .trip-data-tables {
    @include clrfix;
  }

  .trip-cta-a,
  .trip-banner-days {
    text-align: right;
  }

  .trip-cta-b,
  .trip-banner-price {
    text-align: left;
  }

  .trip-banner-days p {
    padding-right: 0;
  }

  // Show cards in IE9
  .js.no-classlist .trip-cards-carousel .summary-card {
    display: block;
  }

  .js-trip-cards-carousel .summary-card {
    display: block;
  }

  .summary-card-data-container {
    width: 100%;
    float: left;
    margin-right: -180px;
  }
  .summary-card-data {
    margin-right: 180px;
  }

  .trip-cards-wrapper .summary-card .cta {
    float: right;
  }

  .nav-row-card .cta,
  .trip-question-card .cta {
    float: none;
  }

  .map-key-item {
    width: 50%;
    float: left;
  }

  .quote-content p, .press-quote-card .quote-content p {
    font-size: 1.4rem;
  }
  // .flickity-slider .quote-marks blockquote {
  //   padding: 0 100px;
  // }

  /* ---------------------- ENQUIRY PAGE */

  .enquiry-trip-content-wrapper {
    @include clrfix;
  }

  /* ---------------------- ANCHOR ROW */

  // .anchor-row-wrapper {
  //   display: block;
  //   padding-bottom: 14px;
  // }
  // .anchor-row {
  //   @include clrfix;
  // }

  /* ---------------------- PAGE CTA */

  .avatar-row {
    text-align: center;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }
  .avatar-row li {
    display: inline-block;
    margin: 0 16px;
    width: auto;
    float: none;
  }
  .avatar-img {
    max-width: 120px;
  }

  .avatar-row-xsmall li {
    margin: 0 8px;
    padding: 0;
  }

  /* ---------------------- GOOGLE MAP */

  .map-canvas {
    height: 640px;
  }

  .flex-box-test {
    @include clrfix;
    font-size: 1em;
    list-style-type: none;
    margin: 0;
  }
  .flex-box-test li {
    // @include gallery(3 of 12);
    background-color: red;
  }
  .flex-head {
    background-color: blue;
  }
  .flex-content {
    background-color: grey;
  }
}
@media screen and (min-width: 1024px) {
  .text-height {
    min-height: 6rem;
  }
}
